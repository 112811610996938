import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';
import './styles/home.scss';
import { Card, Col, Container, Image, Row, Table, Fade } from 'react-bootstrap';
import { ENV } from '../assets/constants';
import axios from 'axios';
import _ from 'underscore';
import moment from 'moment';
import { API_URL } from '../assets/constants';
import { scrollTo } from '../assets/utils';
import { useNavigate } from 'react-router-dom';

/* COMPONENTS */
import CalloutPanel from 'components/calloutPanel/CalloutPanel';
import MotivationQuotes from 'components/motivationQuotes/MotivationQuotes';
import PageHero from 'components/pageHero/PageHero';
import Panel from 'components/panel/Panel';
import WeeklyWorkoutBarChart from 'components/weeklyWorkoutBarChart/WeeklyWorkoutBarChart';

function Home() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [sortedWorkouts, setSortedWorkouts] = useState([]);
  const [workoutDaysInARow, setWorkoutDaysInARow] = useState('');
  const [exercises, setExercises] = useState([]);
  const [lastWorkout, setLastWorkout] = useState('');
  const [lastWorkoutMG, setLastWorkoutMG] = useState('');
  const [latestWorkoutDate, setLatestWorkoutDate] = useState('');
  const [weeklyChartData, setWeeklyChartData] = useState([]);
  const [last5WeeklyAvg, setLast5WeeklyAvg] = useState(0);  

  const navigate = useNavigate();

  useEffect(() => {
     scrollTo('home-page');

     refreshWeeklyTotals(weeklyTotals => {
      if (weeklyTotals) {
        // Build out the chart data
        let chartData = weeklyTotals.workouts;

        // Count totals; weekly workout totals in days
        let total = chartData.reduce((s, f) => {
          return s + f.count;
        }, 0);
        setLast5WeeklyAvg(parseFloat(total / chartData.length, 1).toFixed(1));
        setWeeklyChartData(chartData);
      }      
     });

     async function getWorkouts() {
       const response = await axios.get(API_URL + `/api/workouts/list/${user._id}`);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       return response.data;       
     }
     getWorkouts().then(data => {
      const sortedWorkouts = data.sort((a,b) => new Date(b.date) - new Date(a.date));      

      refreshExercises(exs => {
        let lastWorkoutMuslceGroups = [];

        if (sortedWorkouts[0]) {
          setLatestWorkoutDate(moment(sortedWorkouts[0].date).format("MM/DD/YYYY"));

          sortedWorkouts[0].exercises.map(exercise => {
            const foundExercise = _.findWhere(exs, {_id: exercise.exerciseId});
            if (foundExercise) {

              if (foundExercise.type == 'cardio') {
                lastWorkoutMuslceGroups.push(foundExercise.name);
              } else {
                foundExercise.muscleGroups.map(mG => {
                  if (!lastWorkoutMuslceGroups.includes(mG.name)) {
                    lastWorkoutMuslceGroups.push(mG.name);
                  }
                });
              }
            }
          });

          setLastWorkout(sortedWorkouts[0]);
        }

        setLastWorkoutMG(lastWorkoutMuslceGroups);        
      });
      setSortedWorkouts(sortedWorkouts); // sort by date property
     });
  }, []);

  const refreshWeeklyTotals = callback => {
    async function fetchTotals() {
     const response = await axios.post(API_URL + `/api/workouts/${user._id}/weeklyTotals`, {weeks: 5});

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     if (typeof callback == 'function') callback(response.data);
    }
    fetchTotals();    
  }

  const refreshExercises = callback => {
    async function getExercises() {
     const response = await axios.get(API_URL + `/api/exercises`);

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     setExercises(response.data);
     if (typeof callback == 'function') callback(response.data);
    }
    getExercises();
  }

  return (
    <div id="home-page">
      <PageHero copy={user.firstName ? user.firstName + "'s Dashboard" : 'Dashboard'} cssOptions={{
        bgImage: localStorage.getItem('bgColor'),
      }} />      

      <section id="callout-section">
        <CalloutPanel copy="Exercises" navigateLink="/exercises" bgColor="#AAAAAA" />
        <CalloutPanel copy="Workouts" navigateLink="/workouts" bgColor="#AAAAAA" />        
      </section>

      {
        lastWorkoutMG.length > 0 &&
        <section id="last-workout-section">
          <h1 className="pointer" onClick={() => navigate('/workout-details', {state: {workoutId: sortedWorkouts[0]._id}})}>Latest Workout<br /><small style={{color: '#AAAAAA', fontSize: '1.3rem', opacity: '0.9', fontStyle: 'italic'}}>{latestWorkoutDate}</small></h1>
          <hr style={{border: '1px solid'}} />
          <p>{lastWorkout && lastWorkout.name}</p>
        </section>
      }

      <section id="weekly-totals-section">
        <Card>
          <Card.Body>
            <h1 style={{textAlign: 'center'}}><u>Weekly Workout Totals</u><br /><small>You are averaging {last5WeeklyAvg} workout days a week in the last month</small></h1>
            <div id="week-totals-container">
              {weeklyChartData && <WeeklyWorkoutBarChart customId="weekly-totals-chart" chartData={weeklyChartData.sort((a,b) => new Date(a.dateMin) - new Date(b.dateMin))} dataKeyXAxis="dateMin" dataKeyYAxis="count" />}
            </div>
          </Card.Body>
        </Card>
      </section>

    </div>
  );
}

export default Home;
