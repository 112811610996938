import { React, useEffect, useState } from 'react';
import { Badge, Button, ButtonGroup, InputGroup, Image, Modal, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './exerciseDetailsModal.scss';
import styled from "styled-components";
import _ from 'underscore';
import axios from 'axios';
import moment from 'moment';
import { API_URL } from 'assets/constants';

// IMAGES

// COMPONENTS
import ExerciseRating from '../exerciseRating/ExerciseRating';
import ExerciseTypeButtons from '../exerciseTypeButtons/ExerciseTypeButtons';
import ExerciseHistoryChart from '../exerciseHistoryChart/ExerciseHistoryChart';
import MuscleGroupButtons from '../muscleGroupButtons/MuscleGroupButtons';
import EditableText from '../editableText/EditableText';
import WorkoutLog from '../workoutLog/WorkoutLog';
import MaxWeightBanner from 'components/maxWeightBanner/MaxWeightBanner';

// STYLED COMPONENTS

function ExerciseDetailsModal({ closeModal, exercise, showModal, refresh }) {
  // STATE PARAMS
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [selectedExerciseType, setSelectedExerciseType] = useState(exercise.type);
  const [maxWeight, setMaxWeight] = useState('');

  const handleMuscleGroupClick = newGroups => {
    updateExercise({muscleGroups: newGroups});
  }

  const updateExercise = postObj => {
    if (user.username !== 'drewp6023') {
      window.alert("Must be admin user");
      return;
    }
    
     async function ajaxPost() {
       const response = await axios.post(API_URL + `/api/exercises/${exercise._id}/update`, postObj);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       const resp = response.data;
       refresh();       
     }
     ajaxPost();
  }

  const handleChangeExerciseName = newName => {
    updateExercise({
      name: newName,
    });
  }

  return (
    <Modal show={showModal} onHide={() => closeModal()} size="lg" id="exercise-details-modal" animation={false}>
      {    
        <Modal.Header style={{backgroundColor: '#eeeeee'}} closeButton>
          <Modal.Title as="h2">
            <EditableText initialText={exercise.name} handleUpdate={handleChangeExerciseName} />
          </Modal.Title>
        </Modal.Header>
      }

      <Modal.Body>
        <section id="ratings">
          <ExerciseRating rating={exercise.rating} updateExercise={updateExercise} />
        </section>

        <section style={{marginBottom: '1rem'}}>
          <MuscleGroupButtons exerciseMuscleGroups={exercise.muscleGroups} muscleGroupChange={handleMuscleGroupClick} />
        </section>

        <section id="exercise-type-section">
          <ExerciseTypeButtons activeType={exercise.type} action={updateExercise} />
        </section>

        {
          exercise.imgUrl &&
          <section id="exercise-img-section">
            <Image src={exercise.imgUrl} fluid />
          </section>
        }        

        {
          exercise.type == 'cardio' &&
          <section id="exercise-history-chart-section">
            <h3 style={{margin: '1rem 0', textAlign: 'center'}}>Historical</h3>
            <ExerciseHistoryChart exercise={exercise} />
          </section>
        }

        <section id="workout-log">
          <MaxWeightBanner exerciseId={exercise._id} />
          <WorkoutLog exercise={exercise} exerciseId={exercise._id} />
        </section>

      </Modal.Body>

      <Modal.Footer style={{backgroundColor: '#eeeeee'}}>
        <Button variant="secondary" onClick={() => closeModal()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExerciseDetailsModal;
