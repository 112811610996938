import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const MuscleGroupRadialBarChart = ({ data, showLegend = false }) => {
  const chartRef = useRef();

  useEffect(() => {
    // Clear any existing chart in the container
    d3.select(chartRef.current).selectAll("*").remove();

    // Check if the data is valid
    if (!data || data.length === 0) {
      console.warn("No valid data. Chart will not render.");
      return;
    }
    
    // Filter for valid reps
    const repCheck = data.filter((d) => d.count > 0);    
    // If no valid entries remain, do not render the chart
    if (repCheck.length === 0) {
      console.warn("No valid reps in the data. Chart will not render.");
      return;
    }

    // Get the container dimensions
    const container = chartRef.current;
    const containerWidth = container.offsetWidth || 300; // Default width if not defined
    const containerHeight = container.offsetHeight || 300; // Default height if not defined
    const width = Math.min(containerWidth, containerHeight); // Ensure square chart
    const height = width;

    // Adjust radii dynamically based on the number of muscle groups
    const isSingleGroup = data.filter((d) => d.count > 0).length === 1;
    const innerRadius = isSingleGroup ? width * 0.3 : width * 0.25; // Thicker donut for single group
    const outerRadius = isSingleGroup ? width * 0.43 : width * 0.45; // Adjust outer radius proportionally
    const labelRadius = outerRadius + 5; // Bring labels closer to the arcs
    const color = d3.scaleOrdinal(d3.schemeCategory10); // Color scale

    // Add responsive SVG element
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Filter and process data
    const processedData = data.filter(
      (d) => d.name && typeof d.count === "number" && d.count > 0
    );

    if (processedData.length === 0) {
      console.warn("No valid entries in the data array.");
      return;
    }

    const totalCount = d3.sum(processedData, (d) => d.count);
    const angleScale = d3
      .scaleLinear()
      .domain([0, totalCount])
      .range([0, 2 * Math.PI]);

    const arc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius((d) => innerRadius + (outerRadius - innerRadius) * (d.count / totalCount))
      .startAngle((d, i) => (i === 0 ? 0 : angleScale(d.cumulative)))
      .endAngle((d) => angleScale(d.cumulative + d.count));

    // Compute cumulative counts
    let cumulative = 0;
    const dataWithAngles = processedData.map((d) => {
      d.cumulative = cumulative;
      cumulative += d.count;
      return d;
    });

    // Add arcs
    svg
      .selectAll("path")
      .data(dataWithAngles)
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d, i) => color(i))
      .attr("stroke", "#fff")
      .attr("stroke-width", 2);

    // Add labels
    const fontSize = Math.max(width * 0.04, 12); // Dynamic font size (minimum 12px)
    const labelArc = d3
      .arc()
      .innerRadius(labelRadius) // Reduced label radius
      .outerRadius(labelRadius)
      .startAngle((d, i) => (i === 0 ? 0 : angleScale(d.cumulative)))
      .endAngle((d) => angleScale(d.cumulative + d.count));

    svg
      .selectAll("text")
      .data(dataWithAngles)
      .enter()
      .append("text")
      .attr("transform", (d) => `translate(${labelArc.centroid(d)})`)
      .attr("text-anchor", "middle")
      .attr("font-size", `${fontSize}px`) // Scaled font size
      .attr("fill", "#333")
      .style("font-weight", "bold")
      .text((d) => d.name);

    // Add a legend if showLegend is true
    if (showLegend) {
      const legendFontSize = Math.max(width * 0.03, 10); // Scaled font size (minimum 10px)
      const legend = svg
        .append("g")
        .attr("transform", `translate(${-(width / 2) + 10}, ${-(height / 2) + 10})`);

      legend
        .selectAll("rect")
        .data(dataWithAngles)
        .enter()
        .append("rect")
        .attr("x", 0)
        .attr("y", (d, i) => i * 20)
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", (d, i) => color(i));

      legend
        .selectAll("text")
        .data(dataWithAngles)
        .enter()
        .append("text")
        .attr("x", 20)
        .attr("y", (d, i) => i * 20 + 10)
        .attr("font-size", `${legendFontSize}px`) // Scaled legend font size
        .attr("fill", "#333")
        .text((d) => d.name);
    }
  }, [data, showLegend]);

  return <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>;
};

export default MuscleGroupRadialBarChart;
