import React, { useState, useEffect } from 'react';
import './monthlyEfficiencyMeter.scss';

const MonthlyEfficiencyMeter = ({ workoutData }) => {
  const [progress, setProgress] = useState(0);
  const [workoutDays, setWorkoutDays] = useState(0);
  const [elapsedDays, setElapsedDays] = useState(0);

  useEffect(() => {
    if (workoutData && workoutData.length > 0) {
      // Derive current date
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();
      const currentDay = today.getDate();

      // Filter workouts for the current month and year
      const workoutsInMonth = workoutData.filter((workout) => {
        const workoutDate = new Date(workout.date);
        return (
          workoutDate.getMonth() === currentMonth &&
          workoutDate.getFullYear() === currentYear
        );
      });

      // Count total workout days (including duplicates) for the current month
      const totalWorkoutDays = workoutsInMonth.length;

      // Update state
      setWorkoutDays(totalWorkoutDays);
      setElapsedDays(currentDay);
      setProgress((totalWorkoutDays / currentDay) * 100); // Use days elapsed so far
    } else {
      // Reset values if no data
      setWorkoutDays(0);
      setElapsedDays(0);
      setProgress(0);
    }
  }, [workoutData]); // Run whenever workoutData changes

  return (
    <div id="monthly-efficiency-meter">
      <h3>Monthly Efficiency Progress</h3>
      <div className="progress">
        <div
          className="progress-bar"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p>
        {workoutDays}/{elapsedDays} Workout Days ({!isNaN(Math.round((workoutDays / elapsedDays) * 100)) ? Math.round((workoutDays / elapsedDays) * 100) : 0}% Efficiency)
      </p>
    </div>
  );
};

export default MonthlyEfficiencyMeter;
