import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './exerciseHistoryChart.scss';
import axios from 'axios';
import {
  CartesianGrid,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import moment from 'moment';
import { API_URL } from '../../assets/constants';
import { getMinutes } from '../../assets/utils';

function ExerciseHistoryChart({ exercise }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [chartData, setChartData] = useState('');

  useEffect(() => {
    getExerciseHistory();
  }, []);

  const getExerciseHistory = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/workouts/${user._id}/${exercise._id || exercise.exerciseId}/asc`
      );

      if (response.status === 200 && response.data.length > 0) {
        const newChartData = response.data.map((exercise) => ({
          name: moment(exercise.workoutDate).format('MM-DD-YYYY'),
          type: exercise.type,
          duration: exercise.duration,
          value:
            exercise.type === 'cardio'
              ? getMinutes(
                  exercise.duration.hours,
                  exercise.duration.minutes,
                  exercise.duration.seconds
                )
              : exercise.sets[0]?.weight || '',
        }));
        setChartData(newChartData);
      }
    } catch (error) {
      alert('An error occurred while fetching data: ' + error.message);
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { type, value, duration } = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: '#ffffff',
            border: '1px solid #cccccc',
            borderRadius: '8px',
            padding: '10px',
          }}
        >
          <p><b>Date:</b> {label}</p>
          <p>
            <b>{type === 'weights' ? 'Weight' : 'Duration'}:</b>{' '}
            {type === 'weights'
              ? `${value} lbs`
              : `${duration.hours}:${duration.minutes}:${duration.seconds}`}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {chartData && (
        <div className="exercise-history-chart">
          <ResponsiveContainer>
            <LineChart
              data={chartData}
              margin={{ top: 20, right: 20, bottom: 50, left: 0 }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="name"
                tickFormatter={(date) => moment(date, 'MM-DD-YYYY').format('MMM D')}
                angle={-45}
                textAnchor="end"
                interval="preserveStartEnd"
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#007bff"
                strokeWidth={2}
                dot={{ r: 2 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
}

export default ExerciseHistoryChart;
