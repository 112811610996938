import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './routes.scss';

import PageHero from 'components/pageHero/PageHero';

function WorkoutTemplates() {
  return (
    <div id="workout-templates-page">
      <PageHero copy="Workout Templates" cssOptions={{
        bgImage: localStorage.getItem('bgColor')
      }} />  

      <Container>
        <Row>
          <Col>
            
          </Col>
        </Row>
      </Container>     
    </div>
  );
}

export default WorkoutTemplates;
