import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { exercisesAPI } from 'apiBank';

function MaxWeightBanner({ exerciseId, refresh }) {
  // STATE PARAMS
  const [user] = useState(() => JSON.parse(localStorage.getItem('user')));
  const [maxWeight, setMaxWeight] = useState('');

  useEffect(() => {
    if (exerciseId || refresh) {
      fetchMaxWeight();
    }    
  }, [exerciseId, refresh]); // Only re-run when exerciseId changes

  const fetchMaxWeight = () => {
    console.log("calling...");
    const ajaxCall = async () => {
      try {
        const obj = await exercisesAPI.getMaxMinWeights(user._id, exerciseId);
        if (obj && obj.length > 0) {
          setMaxWeight(obj[0].maxWeight || 0); // Ensure a valid value
        }
      } catch (error) {
        console.error('Error fetching top weight:', error);
      }
    };

    ajaxCall();
  }

  return (
    <h2 style={{ textAlign: 'center' }}>Max Weight: {maxWeight} lbs.</h2>
  );
}

export default MaxWeightBanner;
