import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Accordion, Badge, Button, Card, Form, Image, Input, ListGroup, Modal, Table } from 'react-bootstrap';
import './styles/workoutDetails.scss';
import { makeId, scrollTo, calculateExerciseDifficulty, appendToPageHero } from 'assets/utils';
// import axios from 'axios';
import styled from "styled-components";
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import { API_URL } from 'assets/constants';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts';

// IMAGES
import HealthImg from '../assets/images/icons/health_icon.svg';
import StretchImg from '../assets/images/icons/stretching.svg';
import SickImg from '../assets/images/icons/sick.svg';

// FONT AWESOME
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faTrash, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

/* COMPONENTS */
import PageHero from '../components/pageHero/PageHero';
import AddExerciseModal from '../components/addExerciseModal/AddExerciseModal';
import EditWorkoutModal from '../components/editWorkoutModal/EditWorkoutModal';
import AddNoteModal from '../components/addNoteModal/AddNoteModal';
import RecentWorkoutVolumeChart from '../components/recentWorkoutVolumeChart/RecentWorkoutVolumeChart';
import IntensityMeter from '../components/intensityMeter/IntensityMeter';
import IntensityMeterNew from '../components/intensityMeterNew/IntensityMeterNew';
import MuscleGroupRadialBarChart from '../components/muscleGroupRadialBarChart/MuscleGroupRadialBarChart';
import StarRating from 'components/starRating/StarRating';

const Spacer = styled.div`
  margin-bottom: 1rem;
`;

const getUnitStr = unit => {
  const strArr = {
    hours: 'hr(s)',
    minutes: 'min',
    seconds: 's',
  };

  return strArr[unit];
}

function WorkoutDetails() {
  const location =  useLocation();
  const navigate = useNavigate();
  const workoutId = location.state?.workoutId;
  const skipScroll = location.state?.skipScroll;

  // STATE PARAMETERS
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  // const [workoutId, setWorkoutId] = useState(location.state?.workoutId || '');
  const [workout, setWorkout] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [showAddExerciseModal, setShowAddExerciseModal] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState({});
  const [allExercises, setAllExercises] = useState([]);
  const [showEditWorkoutModal, setShowEditWorkoutModal] = useState(false);
  const [workoutName, setWorkoutName] = useState('');
  const [workoutDate, setWorkoutDate] = useState('');
  const [totalSets, setTotalSets] = useState(0);
  const [totalReps, setTotalReps] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [muscleGroupString, setMuscleGroupString] = useState('');
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [activeNote, setActiveNote] = useState('');
  const [currentMuscleGroupCount, setCurrentMuscleGroupCount] = useState([]);
  const [trainingGoals, setTrainingGoals] = useState([]);
  const [workoutMetrics, setWorkoutMetrics] = useState({});

  useEffect(() => {
     if (!skipScroll) scrollTo('workout-details-page');     
     refreshAllExercises(exercises => {
      refreshWorkout(null, exercises);
      refreshTrainingGoals();
     });

    if (!workoutId) {
      window.alert('Workout ID not provided.');
      navigate('/workouts'); // Redirect back to a safe route
    }     
  }, [workoutId, navigate]);

  const refreshAllExercises = callback => {
    async function getExercises() {
     const response = await axios.get(API_URL + `/api/exercises`);

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     return response.data;
    }
    getExercises()
      .then(exercises => {
        setAllExercises(exercises);

        if (typeof callback == 'function') callback(exercises);
      });
  }

  const refreshTrainingGoals = () => {
    async function getTG() {
     const response = await axios.get(API_URL + `/api/trainingGoal/list`);

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     setTrainingGoals(response.data);
    }
    getTG();    
  }

  const refreshWorkout = (callback, exerciseObjs) => {
     async function getWorkout() {
       const response = await axios.get(API_URL + `/api/workouts/${workoutId}`);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       return response.data;       
     }
     getWorkout().then(data => {
      calculateExerciseDifficulty({

      });    

      // Grab total sets and reps
      if (data.exercises.length > 0) {
        let setCount = 0;
        let repCount = 0;
        let weightTotal = 0;

        data.exercises.forEach((exercise, i) => {
          setCount += exercise.sets.length;

          if (exercise.sets.length > 0) {
            exercise.sets.forEach(set => {
              if (set.reps) repCount += parseInt(set.reps);
              if (set.weight) weightTotal += parseInt(set.weight);
            });
          }

          // Get the exercise type
          const aE = allExercises.length > 0 ? allExercises : exerciseObjs;
          if (aE.length > 0) {
            data.exercises[i].type = _.findWhere(aE, { _id: exercise.exerciseId }).type || '';
          }
        });      
        setTotalSets(setCount);
        setTotalReps(repCount);
        setTotalWeight(weightTotal);

        setWorkoutMetrics(calculateExerciseDifficulty({
          weight: setCount,
          reps: repCount,
          sets: weightTotal,
        })); 
        // console.log('workoutMetrics', calculateExerciseDifficulty({
        //   weight: setCount,
        //   reps: repCount,
        //   sets: weightTotal,
        // })); 

        // Update the set and rep count on the model - can be asynchronous
       async function updateWorkout() {
         const response = await axios.put(API_URL + `/api/workouts/${workoutId}/update`, {
          setCount: setCount,
          repCount: repCount,
         });
       }
       updateWorkout();   
      }

      setWorkoutName(data.name);
      setWorkoutDate(data.date);
      setWorkout(data);
      setExercises(data.exercises);

      if (typeof callback == 'function') callback(data);
     })     
  }

  const getMuscleGroupString = (exercises, asObj = false) => {
    let currentMuscleGroups = [];
    let mgObjs = [];

    if (exercises) {
      exercises.map(exercise => {
        const foundExercise = allExercises.find(aE => aE._id.toString() == exercise.exerciseId.toString());
        if (foundExercise) {
          foundExercise.muscleGroups.map(mG => {
            if (!currentMuscleGroups.includes(mG.name)) {
              currentMuscleGroups.push(mG.name);              
            }
            // Only deal with the weights and exclude cardio (for now)
            if (foundExercise.type == 'weights' && !_.findWhere(mgObjs, {name: mG.name})) mgObjs.push({name: mG.name, count: 0}); // Make sure we have an object for this muscle group
            const index = _.findIndex(mgObjs, {name: mG.name});

            if (index > -1) {
              // Calculate the reps so we can send use as chart data
              const reps = exercise.sets.map((set, SI) => {
                return parseInt(set.reps);
              })
              reps.map(count => {
                mgObjs[index].count = parseInt(mgObjs[index].count) + parseInt(count);
              })
            }
          })
        }
      });        
    }

    return asObj === true ? mgObjs : currentMuscleGroups.join(", ");
  }

  const openAddExerciseModal = () => {
    setShowAddExerciseModal(true);
  }  
  const closeAddExerciseModal = () => {
    setShowAddExerciseModal(false);
    setSelectedExercise({});
  }

  const openAddNoteModal = () => {
    setShowAddNoteModal(true);
  }
  const closeAddNoteModal = () => {
    setShowAddNoteModal(false);
  }

  const goToWorkouts = () => {
    navigate('/workouts');
  }

  const deleteWorkout = e => {
    if (window.confirm('Are you sure you want to delete this workout?')) {
       async function deleteWorkout() {
         const response = await axios.delete(API_URL + `/api/workouts/${workoutId}`);

         if (response.status !== 200) {
           const message = `An error occurred: ${response.statusText}`;
           window.alert(message);
           return;
         }
     
         navigate('/workouts');
       }
       deleteWorkout();       
    }
  }

  const saveExercise = (exercise, callback) => {
    if (!exercise.name) {
      alert("Please provide an exercise name.");
      return;
    }

    // Make sure to cast weights and reps to proper type
    exercise.sets.map((set, i) => {
      exercise.sets[i].weight = parseFloat(set.weight);
      exercise.sets[i].reps = parseInt(set.reps);
    })

    // If there isn't a unique id then we know to insert this new exercise
    if (!_.findWhere(workout.exercises, {id: exercise.id})) {
      // No exercises yet, add one
       async function addExercise() {
         const response = await axios.post(API_URL + `/api/workouts/${workoutId}/exercise/add`, exercise);

         if (response.status !== 200) {
           const message = `An error occurred: ${response.statusText}`;
           window.alert(message);
           return;
         }
     
         // const workouts = response.data;
         refreshWorkout();

         if (typeof callback == 'function') callback();
       }
       addExercise();        
    } else {
      // Check for the id already in the exercises, if so, then we want to update this exercise instead of create a new one
       async function updateExercise() {
         const response = await axios.post(API_URL + `/api/workouts/${workoutId}/exercise/${exercise.id}/update`, exercise);

         if (response.status !== 200) {
           const message = `An error occurred: ${response.statusText}`;
           window.alert(message);
           return;
         }
     
         // const workouts = response.data;
         refreshWorkout();

         if (typeof callback == 'function') callback(exercise);
       }
       updateExercise(); 
    }  

    // setShowAddExerciseModal(false);
  }

  const removeExercise = exercise => {
    if (window.confirm("Are you sure you want to delete this exercise?")) {
       async function deleteExercise() {
         const response = await axios.delete(API_URL + `/api/workouts/${workoutId}/exercise/${exercise.id}`);

         if (response.status !== 200) {
           const message = `An error occurred: ${response.statusText}`;
           window.alert(message);
           return;
         }
     
         refreshWorkout();
       }
       deleteExercise(); 
    }
  }

  const handleSelectedExercise = (exercise) => {
    setSelectedExercise(exercise);
    setShowAddExerciseModal(true);
  }

  const openEditWorkoutModal = () => {
    setShowEditWorkoutModal(true);
  }

  const closeEditWorkoutModal = () => {
    setShowEditWorkoutModal(false);
  }

  const handleNoteClick = note => {
    setActiveNote(note);
    setShowAddNoteModal(true);
  }

  const removeNote = note => {
    if (window.confirm("Are you sure you want to remove this note?")) {
       async function deleteExercise() {
         const response = await axios.delete(API_URL + `/api/workouts/${workoutId}/note/${note.id}`);

         if (response.status !== 200) {
           const message = `An error occurred: ${response.statusText}`;
           window.alert(message);
           return;
         }
     
         refreshWorkout();
       }
       deleteExercise(); 
    }
  }

  const toggleFavorite = async () => {
    // UPDATE THE WORKOUT FAVORITE
    const response = await axios.put(API_URL + `/api/workouts/${workoutId}/update`, {favorite: !workout.favorite});
    setWorkout({...workout, favorite: !workout.favorite});
  }

  const goToWorkout = async (direction) => {
    if (!workout || !workout._id) {
      window.alert('Workout data is not ready yet.');
      return;
    }

    let url;
    if (direction === 'prev') {
      url = `${API_URL}/api/workouts/${workout._id}/${user._id}/previous`;
    } else if (direction === 'next') {
      url = `${API_URL}/api/workouts/${workout._id}/${user._id}/next`;
    } else {
      window.alert('Invalid direction.');
      return;
    }

    try {
      const response = await axios.get(url);
      if (response.status !== 200 || !response.data._id) {
        throw new Error('Invalid response from API.');
      }

      navigate('/workout-details', {
        state: { workoutId: response.data._id, skipScroll: true },
      });

    } catch (error) {
      console.error('Error navigating to workout:', error);
      window.alert('Failed to navigate to the workout. Please try again.');
    }
  };

  return (
    <>
      {showAddExerciseModal && <AddExerciseModal exercise={selectedExercise} showModal={true} saveExercise={saveExercise} closeModal={() => closeAddExerciseModal()} />}
      {
        showEditWorkoutModal &&
        <EditWorkoutModal workout={workout} onModalClose={closeEditWorkoutModal} refreshWorkout={refreshWorkout} />      
      }
      {
        showAddNoteModal &&
        <AddNoteModal note={activeNote} workout={workout} onModalClose={closeAddNoteModal} refreshWorkout={refreshWorkout} />      
      }

      <div id="workout-details-page">
          <PageHero copy={workout.name} handleClick={openEditWorkoutModal} cssOptions={{
            bgImage: localStorage.getItem('bgColor'),
          }} />    

          <section id="details-section">
            <div id="button-container">
              {
                  !workout.isFirstWorkout &&
                  <button onClick={() => goToWorkout('prev')} className="custom-goto-button">
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button> 
              }
              {' '}
              {
                  !workout.isMostRecent &&
                  <button onClick={() => goToWorkout('next')} className="custom-goto-button">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
              }
              <Button variant="success" onClick={openAddExerciseModal}>+ Exercise</Button>
              <Button variant="success" onClick={openAddNoteModal}>+ Note</Button>
              <Button variant="warning" onClick={openEditWorkoutModal}>Edit</Button>
              <Button variant="danger" onClick={deleteWorkout}>Delete</Button>
              <Button variant={workout.favorite ? 'dark' : 'light'} style={{border: '1px solid black', color: (workout.favorite ? 'red' : '')}} onClick={toggleFavorite}><FontAwesomeIcon icon={faHeart} /></Button>
            </div>

            <Spacer />

            {
              workout.exercises &&
              <section id="total-sets-reps" style={{textAlign: 'center'}}>
                <Table variant="dark" bordered responsive>
                  <tbody>
                    <tr>
                      <th>Date</th>
                      <td>{moment(workout.date).format('M/D/YYYY h:mm a')}</td>
                    </tr>                  
                    <tr>
                      <th>Goal</th>
                      <td>{trainingGoals.length > 0 && workout.training_goal_id && _.findWhere(trainingGoals, {_id: workout.training_goal_id}).name}</td>
                    </tr>
                    <tr>
                      <th>Total Sets</th>
                      <td>{totalSets || 0}</td>
                    </tr>
                    <tr>
                      <th>Total Reps</th>
                      <td>{totalReps || 0}</td>
                    </tr>
                    <tr>
                      <th>Total Weight</th>
                      <td>{totalWeight || 0}</td>
                    </tr>                    
                    <tr>
                      <th>Muscle Groups</th>
                      <td>{getMuscleGroupString(workout.exercises)}</td>
                    </tr>       
                    <tr>
                      <th>Attributes</th>
                      <td>
                        <div id="icon-section">
                          {
                            workout.injury &&
                            <div className="icon-container">
                              <Image fluid className="icon" src={HealthImg} />
                            </div>
                          }
                          {
                            workout.stretched &&
                            <div className="icon-container">
                              <Image fluid className="icon" src={StretchImg} />
                            </div>
                          } 
                          {
                            workout.sick &&
                            <div className="icon-container">
                              <Image fluid className="icon" src={SickImg} />
                            </div>
                          }                                
                        </div>                  
                      </td>
                    </tr>
                    <tr>
                      <th>Rating</th>
                      <td><StarRating style={{fontSize: '1.5rem'}} locked={true} rating={workout.rating} /></td>
                    </tr>                                                                                
                  </tbody>

                </Table>
              </section>
            }

            {
              totalReps > 0 &&
              <>
                <h2 className="text-center">Workout Intensity {workoutMetrics && workoutMetrics.intensityCategory == 'Very High' && '<span> - Maximum Effort</span>'}</h2>

                <IntensityMeter totalReps={totalReps} />
                
                <Spacer />
              </>
            }

            {workout.exercises && <MuscleGroupRadialBarChart data={getMuscleGroupString(workout.exercises, true)} />}

{/*            {
              totalReps > 0 &&
              <div id="radar-chart-container">
                <ResponsiveContainer width="100%" height="100%">
                  <RadarChart width={400} height={400} cx="50%" cy="50%" outerRadius="80%" data={getMuscleGroupString(workout.exercises, true)}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="name" />
                    <PolarRadiusAxis />
                    <Tooltip />
                    <Radar name="Reps" dataKey="count" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                  </RadarChart>
                </ResponsiveContainer>  
              </div>
            }  */}        

            <Spacer />

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header><h2>Exercises</h2></Accordion.Header>
                <Accordion.Body>
                  {
                    exercises && _.where(exercises, {type: 'weights'}).length > 0 ?
                    <ListGroup>
                    {
                      exercises.map((exercise, i) => (
                        _.findWhere(allExercises, {_id: exercise.exerciseId}) && _.findWhere(allExercises, {_id: exercise.exerciseId}).type == 'weights' &&
                        <ListGroup.Item key={i} className="d-flex justify-content-between align-items-start" as="li">
                          <div className="ms-2 me-auto">
                            <div className="fw-bold" onClick={() => handleSelectedExercise(exercise)}>{exercise.name}</div>
                            {
                              exercises.length > 0 &&
                              exercise.sets.map((set, setI) => (
                                <Badge key={setI} bg={set.warmup ? 'info' : 'primary'} pill className="set-badge">
                                  {
                                    (
                                      allExercises.length > 0 
                                        && _.findWhere(allExercises, {_id: exercise.exerciseId}) 
                                        && _.findWhere(allExercises, {_id: exercise.exerciseId}).type == 'weights') 
                                      ? 
                                      (set.weight + 'x' + set.reps) 
                                      : ''
                                  }
                                </Badge>
                              ))                            
                            } 
                          </div>                                                    
                          <Badge bg="danger" pill>
                            <FontAwesomeIcon icon={faTrash} onClick={e => removeExercise(exercise)} />
                          </Badge>                          
                        </ListGroup.Item>
                      ))                      
                    }
                    </ListGroup>
                    : <p>No Exercises</p>
                  }
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header><h2>Cardio</h2></Accordion.Header>
                <Accordion.Body>
                  {
                    exercises && _.where(exercises, {type: 'cardio'}).length > 0 ?
                    <ListGroup>
                    {
                      exercises.map((exercise, i) => (
                        _.findWhere(allExercises, {_id: exercise.exerciseId}) && _.findWhere(allExercises, {_id: exercise.exerciseId}).type == 'cardio' &&
                        <ListGroup.Item key={i} className="d-flex justify-content-between align-items-start" as="li">
                          <div className="ms-2 me-auto">
                            <div className="fw-bold" onClick={() => handleSelectedExercise(exercise)}>{exercise.name}</div>
                            {
                              exercises.length > 0 &&
                              exercise.sets.map((set, setI) => (
                                <Badge key={setI} bg="primary" pill className="set-badge">
                                  {
                                    exercise.duration &&
                                    (exercise.duration.hours + ':' + exercise.duration.minutes + ':' + exercise.duration.seconds)
                                  }
                                </Badge>
                              ))                            
                            } 
                          </div>                                                    
                          <Badge bg="danger" pill>
                            <FontAwesomeIcon icon={faTrash} onClick={e => removeExercise(exercise)} />
                          </Badge>                          
                        </ListGroup.Item>
                      ))                      
                    }
                    </ListGroup>
                    : <p>No Cardio</p>
                  }
                </Accordion.Body>
              </Accordion.Item>

              {
                _.findWhere(workout.exercises, {type: 'weights'}) &&
                <Accordion.Item eventKey="2">
                  <Accordion.Header><h2>Volume History</h2></Accordion.Header>
                  <Accordion.Body>
                    <p style={{color: '#AAAAAA', fontStyle: 'italic', textAlign: 'center'}}>(Previous 5 workouts)</p>
                    <section id="volume-chart-section">
                      {workout.exercises && <RecentWorkoutVolumeChart selectedWorkout={workout} />}
                    </section>
                  </Accordion.Body>
                </Accordion.Item>
              }

              <Accordion.Item eventKey="3">
                <Accordion.Header><h2>Notes</h2></Accordion.Header>
                <Accordion.Body>
                  {
                    workout.notes && workout.notes.length > 0 ?
                    workout.notes.map((note, noteI) => (
                      <Card key={noteI} bg="light" className="note-card">
                        <Card.Body><div dangerouslySetInnerHTML={{__html: note.note}} onClick={() => handleNoteClick(note)} /></Card.Body>
                        <Card.Footer className="text-muted">
                          {moment(note.timestamp).format('lll')}
                          <Badge style={{float: 'right'}} bg="danger" pill>
                            <FontAwesomeIcon icon={faTrash} onClick={e => removeNote(note)} />
                          </Badge>                          
                        </Card.Footer>
                      </Card>
                    ))
                    : <p>No Notes</p>
                  }
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>          
          </section>
      </div>
    </>
  );
}

export default WorkoutDetails;
