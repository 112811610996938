import React, { useEffect, useState } from 'react';
import { CartesianGrid, Cell, BarChart, Bar, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList } from 'recharts';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.css';
import './weeklyWorkoutBarChart.scss';

/**
 * Props:
 *  customId
 *  chartData: Array of weekly workout data objects
 *  dataKeyXAxis: Key for x-axis (e.g., "dateMin")
 *  dataKeyYAxis: Key for y-axis (e.g., "count")
 */
function WeeklyWorkoutBarChart({ customId, chartData, dataKeyXAxis, dataKeyYAxis }) {
  const [holidays, setHolidays] = useState([]);

  // Relevant holidays with their respective dates for 2024
  const getHolidayDates = (year) => {
    // Helper to calculate the nth occurrence of a weekday in a month
    const nthWeekdayOfMonth = (year, month, weekday, n) => {
      const firstDay = new Date(year, month, 1);
      const firstWeekday = (7 + weekday - firstDay.getDay()) % 7;
      const nthDay = 1 + firstWeekday + (n - 1) * 7;
      return new Date(year, month, nthDay);
    };

    return [
      { name: "New Year's", date: `${year}-01-01` },
      { name: 'Memorial Day', date: nthWeekdayOfMonth(year, 4, 1, -1).toISOString().slice(0, 10) }, // Last Monday of May
      { name: '4th of July', date: `${year}-07-04` },
      { name: 'Labor Day', date: nthWeekdayOfMonth(year, 8, 1, 1).toISOString().slice(0, 10) }, // First Monday of September
      { name: 'Columbus Day', date: nthWeekdayOfMonth(year, 9, 1, 2).toISOString().slice(0, 10) }, // Second Monday of October
      { name: 'Thanksgiving', date: nthWeekdayOfMonth(year, 10, 4, 4).toISOString().slice(0, 10) }, // Fourth Thursday of November
      { name: 'Christmas', date: `${year}-12-25` },
    ];
  };

  // Extract the year dynamically, e.g., from chartData or current year
  const chartYear = chartData?.year || new Date().getFullYear();
  const relevantHolidays = getHolidayDates(chartYear);

  useEffect(() => {
    // Extract dates of relevant holidays into a state array
    setHolidays(relevantHolidays);
  }, []);

  // Check if a week contains a holiday
  const getHolidayForWeek = (week) => {
    for (let date of week.dateRanges) {
      const formattedDate = moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
      const holiday = holidays.find((h) => h.date === formattedDate);
      if (holiday) return holiday.name; // Return the holiday name
    }
    return null; // No holiday for this week
  };

  // Helper to check if a date is in the current week
  const isCurrentWeek = (dateObj) => {
    const today = moment(); // Current date
    const startDate = moment(dateObj.dateMin, "MM/DD/YYYY"); // Convert dateMin to moment
    const endDate = moment(dateObj.dateMax, "MM/DD/YYYY"); // Convert dateMax to moment
    return today.isBetween(startDate, endDate, "day", "[]"); // Check inclusively
  };

  return (
    <div id={customId || 'weekly-workout-bar-chart'} className="weekly-workout-bar-chart">
      {chartData.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={400}
            height={400}
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 0,
              bottom: 50,
            }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <XAxis dataKey={dataKeyXAxis} angle={-45} textAnchor="end" />
            <YAxis />
            <Tooltip />
            <Bar
              type="monotone"
              radius={5}
              dataKey={dataKeyYAxis}
              fill="#001f3f"
            >
              {chartData.map((entry, index) => {
                const holidayName = getHolidayForWeek(entry); // Get the holiday for this week
                const isCurrent = isCurrentWeek(entry); // Check if today falls in this week's range
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={holidayName ? "#BC3F00" : isCurrent ? "#82ca9d" : "#001f3f"} // Highlight current week in blue, holidays in red
                  />
                );
              })}
              {/* Workout count label at the top of the bar */}
              <LabelList
                dataKey={dataKeyYAxis}
                position="top"
                fill="black" // Default color for workout count
                fontSize={16}
                fontWeight="bold"
              />
              {/* Holiday name centered in the bar */}
              <LabelList
                dataKey={(entry) => {
                  const holidayName = getHolidayForWeek(entry);
                  return holidayName || ''; // Show holiday name or nothing
                }}
                position="center"
                content={(props) => {
                  const { x, y, width, height, value } = props;
                  return (
                    value && (
                      <text
                        x={x + width / 2} // Center horizontally in the bar
                        y={y + height / 2} // Center vertically in the bar
                        fill="white" // White text for holiday name
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize="12"
                        fontWeight="bold"
                      >
                        {value}
                      </text>
                    )
                  );
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <p>No chart data available</p>
      )}
    </div>
  );
}

export default WeeklyWorkoutBarChart;
