import axios from 'axios';
import { API_URL } from './assets/constants'; // Import your API base URL from constants

// Users API
export const usersAPI = {
  getUsers: async () => axios.get(`${API_URL}/api/users`).then(res => res.data),
  getUserById: async (id) => axios.get(`${API_URL}/api/users/${id}`).then(res => res.data),
  insertUser: async (userData) => axios.post(`${API_URL}/api/users`, userData).then(res => res.data),
  updateUser: async (userId, userData) => axios.put(`${API_URL}/api/user/${userId}/update`, userData).then(res => res.data),
  deleteUser: async (id) => axios.delete(`${API_URL}/api/users/${id}`).then(res => res.data),
};

// Workouts API
export const workoutsAPI = {
  getWorkouts: async () => axios.get(`${API_URL}/api/workouts`).then(res => res.data),
  getWorkoutById: async (id) => axios.get(`${API_URL}/api/workouts/${id}`).then(res => res.data),
  getWorkoutsByUserId: async (userId) => axios.get(`${API_URL}/api/workouts/list/${userId}`).then(res => res.data),
  getWorkoutsByMonth: async (userId, monthNo) => axios.get(`${API_URL}/api/workouts/list/${userId}/${monthNo}`).then(res => res.data),
  insertWorkout: async (workoutData) => axios.post(`${API_URL}/api/workouts`, workoutData).then(res => res.data),
  updateWorkout: async (id, workoutData) => axios.put(`${API_URL}/api/workouts/${id}/update`, workoutData).then(res => res.data),
  deleteWorkout: async (id) => axios.delete(`${API_URL}/api/workouts/${id}`).then(res => res.data),
  addNoteToWorkout: async (id, note) => axios.post(`${API_URL}/api/workouts/${id}/add_note`, { note }).then(res => res.data),
  updateWorkoutNote: async (id, noteId, noteData) =>
    axios.put(`${API_URL}/api/workouts/${id}/note/${noteId}`, noteData).then(res => res.data),
  deleteWorkoutNote: async (id, noteId) => axios.delete(`${API_URL}/api/workouts/${id}/note/${noteId}`).then(res => res.data),
};

// Exercises API
export const exercisesAPI = {
  getExercises: async () => axios.get(`${API_URL}/api/exercises`).then(res => res.data),
  getExerciseById: async (id) => axios.get(`${API_URL}/api/exercises/${id}`).then(res => res.data),
  insertExercise: async (exerciseData) => axios.post(`${API_URL}/api/exercises`, exerciseData).then(res => res.data),
  updateExercise: async (exerciseId, exerciseData) =>
    axios.post(`${API_URL}/api/exercises/${exerciseId}/update`, exerciseData).then(res => res.data),
  deleteExercise: async (id) => axios.delete(`${API_URL}/api/exercises/${id}`).then(res => res.data),
  getExercisesByType: async () => axios.get(`${API_URL}/api/exercises/byType`).then(res => res.data),
  getMaxMinWeights: async (userId, exerciseId = '') =>
    axios.get(`${API_URL}/api/exercises/${userId}/maxMinWeights/${exerciseId}`).then(res => res.data),  
};

// Muscle Groups API
export const muscleGroupsAPI = {
  getMuscleGroups: async () => axios.get(`${API_URL}/api/muscleGroup/list`).then(res => res.data),
  getMuscleGroupById: async (id) => axios.get(`${API_URL}/api/muscleGroup/${id}`).then(res => res.data),
  insertMuscleGroup: async (muscleGroupData) =>
    axios.post(`${API_URL}/api/muscleGroup/insert`, muscleGroupData).then(res => res.data),
  updateMuscleGroup: async (muscleGroupId, muscleGroupData) =>
    axios.post(`${API_URL}/api/muscleGroup/${muscleGroupId}/update`, muscleGroupData).then(res => res.data),
  deleteMuscleGroup: async (id) => axios.delete(`${API_URL}/api/muscleGroup/${id}`).then(res => res.data),
};

// Training Goals API
export const trainingGoalsAPI = {
  getTrainingGoals: async () => axios.get(`${API_URL}/api/trainingGoal/list`).then(res => res.data),
};

// Color Gradients API
export const colorGradientsAPI = {
  getColorGradients: async () => axios.get(`${API_URL}/api/colorGradient/list`).then(res => res.data),
};

// Workout Templates API
export const workoutTemplatesAPI = {
  getWorkoutTemplates: async () => axios.get(`${API_URL}/api/workout_template`).then(res => res.data),
  getWorkoutTemplateById: async (id) => axios.get(`${API_URL}/api/workout_template/${id}`).then(res => res.data),
  insertWorkoutTemplate: async (templateData) => axios.post(`${API_URL}/api/workout_template`, templateData).then(res => res.data),
  updateWorkoutTemplate: async (id, templateData) => axios.put(`${API_URL}/api/workout_template/${id}/update`, templateData).then(res => res.data),
  deleteWorkoutTemplate: async (id) => axios.delete(`${API_URL}/api/workout_template/${id}`).then(res => res.data),
  getWorkoutTemplatesByUserId: async (userId) => axios.get(`${API_URL}/api/workout_template/user/${userId}`).then(res => res.data),
};
