import React from 'react';
import DBIcon from '../../db_icon.png';
import 'bootstrap/dist/css/bootstrap.css';
import './defaultNavbar.scss';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import AsciiChar from '../asciiChar/AsciiChar';

function DefaultNavbar({fixed, userLogout}) {
  const userObj = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const handleLogout = () => {
    userLogout();
  }

  const handleHomeClick = event => {
    var elems = document.querySelectorAll("a.nav-link.active");

    [].forEach.call(elems, function(el) {
        el.classList.remove("active");
    });
  }

  return (
    <div id="default-navbar">
      <Navbar collapseOnSelect expand="lg" variant="dark" bg="dark" variant="dark" fixed={fixed}>
        <Container fluid>
          <LinkContainer to="/home">
            <Nav.Link>
            <Navbar.Brand>
              <img
                src={DBIcon}
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt="Ananta logo"
                onClick={(e) => handleHomeClick(e)}
              />
            </Navbar.Brand>
            </Nav.Link>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/home"><Nav.Link>Dashboard</Nav.Link></LinkContainer>
              <LinkContainer to="/exercises"><Nav.Link>Exercises</Nav.Link></LinkContainer>
              <LinkContainer to="/profile"><Nav.Link>Profile</Nav.Link></LinkContainer>
              <LinkContainer to="/workouts"><Nav.Link>Workouts</Nav.Link></LinkContainer>
              <LinkContainer to="/workout-plan"><Nav.Link>Workout Plan</Nav.Link></LinkContainer>
              <LinkContainer to="/workout-templates"><Nav.Link>Workout Templates</Nav.Link></LinkContainer>
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {
            userObj &&
            <Nav className="hidden-xs">
              <LinkContainer to="/home" style={{color: 'white'}}><Nav.Link>Hi {userObj.firstName || ''} <AsciiChar /></Nav.Link></LinkContainer>
            </Nav>
          }
        </Container>
      </Navbar>
    </div>
  );
}

export default DefaultNavbar;
