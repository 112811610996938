import { React, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './pageHero.scss';
import axios from 'axios';
import { API_URL } from '../../assets/constants';
import ConfettiExplosion from 'react-confetti-explosion';
import moment from 'moment';

/* COMPONENTS */
import WDRB from '../workoutDaysInARowBanner/WorkoutDaysInARowBanner';

function PageHero({ copy, cssOptions, showBanner = true, handleClick, refresh }) {
  /* STATE */
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [isExploding, setIsExploding] = useState(true);

  useEffect(() => {
    refreshUser();
  }, [refresh]);

  const refreshUser = async () => {
    const response = await axios.get(API_URL + `/api/users/${user._id}`);
    setUser(response.data);
  }

  return (
    <>
    <div id="page-hero" style={{ 
        backgroundColor: (cssOptions && cssOptions.bgColor || 'black'), 
        color: (cssOptions && cssOptions.textColor || 'white'), 
        background: (cssOptions.background || user.gradient || ''),
      }}>
      <div id="copy">        
        <span onClick={e => typeof handleClick == 'function' && handleClick(copy)}>{ copy }</span>        
      </div>      
      {isExploding && (moment(user.birthday).format('MM/DD/YYYY') == moment().format('MM/DD/YYYY')) && <ConfettiExplosion />}
    </div>
    {showBanner && <WDRB runAnimation={true} animationDelay={200} />}
    </>
  );
}

export default PageHero;
