import React, { Fragment, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './workoutLog.scss';
import { Table, Accordion } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../assets/constants';

import WorkoutVisualization from 'components/workoutVisualization/WorkoutVisualization';

const ExtraSets = ({ curLength, highestSet, header }) => {
  let elements = [];
  const diff = highestSet - curLength;

  for (let i = 0; i < diff; i++) {
    if (header) {
      elements.push(<th key={i}>Set #{curLength + (i + 1)}</th>);
    } else {
      elements.push(<td key={i} style={{ verticalAlign: 'middle', textAlign: 'center' }}></td>);
    }
  }

  return elements;
};

function WorkoutLog({ exercise, exerciseId, refresh }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [workoutLog, setWorkoutLog] = useState({ workouts: [], highestSet: 0 });

  useEffect(() => {
    if (exercise && exerciseId) {
      refreshTable();
    }
  }, [exercise, exerciseId, refresh]);

  const refreshTable = () => {
    async function ajaxPost() {
      try {
        const response = await axios.get(`${API_URL}/api/workouts/${user._id}/${exerciseId}/desc`);
        if (response.status !== 200) {
          const message = `An error occurred: ${response.statusText}`;
          window.alert(message);
          return;
        }

        let count = 1;
        response.data.forEach((w) => {
          if (w.sets.length >= count) count = w.sets.length;
        });

        setWorkoutLog({ workouts: response.data, highestSet: count });
      } catch (error) {
        console.error("Error fetching workout log:", error);
      }
    }
    ajaxPost();
  };

  return (
    <div className="workout-log">
      <Accordion defaultActiveKey={null}>
        {/* Workout Log Accordion */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>Workout Log</Accordion.Header>
          <Accordion.Body>            
            <Table bordered condensed="true" responsive id="main-table">
              <thead></thead>
              <tbody>
                {workoutLog.workouts.map((workout, i) => (
                  <tr key={i}>
                    <td style={{ verticalAlign: 'middle' }}>
                      {moment(workout.workoutDate).format('MM/DD/YYYY')}
                    </td>
                    <td>
                      <Table striped bordered condensed="true" style={{ margin: '0' }} id="secondary-table">
                        <thead>
                          <tr>
                            {workout.sets.map((set, sI) => (
                              <Fragment key={sI}>
                                <th>Set #{sI + 1}</th>
                                {sI + 1 === workout.sets.length && (
                                  <ExtraSets
                                    curLength={sI + 1}
                                    highestSet={workoutLog.highestSet}
                                    header
                                  />
                                )}
                              </Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {exercise.type === 'weights' &&
                              workout.sets.map((set, sI) => (
                                <Fragment key={sI}>
                                  <td style={{backgroundColor: set.warmup > 0 && '#0ccaf0', color: set.warmup > 0 && 'white'}}>{`${set.weight}x${set.reps}`}</td>
                                  {sI + 1 === workout.sets.length && (
                                    <ExtraSets
                                      curLength={sI + 1}
                                      highestSet={workoutLog.highestSet}
                                      header={false}
                                    />
                                  )}
                                </Fragment>
                              ))}
                            {exercise.type === 'cardio' && workout.duration && (
                              <Fragment>
                                <td>
                                  {`${workout.duration.hours ? `${workout.duration.hours} hours ` : ''}${
                                    workout.duration.minutes ? `${workout.duration.minutes} minutes ` : ''
                                  }${workout.duration.seconds ? `${workout.duration.seconds} seconds` : ''}`}
                                </td>
                              </Fragment>
                            )}
                          </tr>
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>

        {/* Conditional Accordion Items */}
        {exercise.type !== 'cardio' && (
          <>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Rep Visualization</Accordion.Header>
              <Accordion.Body>
                {workoutLog && <WorkoutVisualization showTitle={false} customWidth="500px" data={workoutLog.workouts} timeRange="quarterly" />}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Weight Visualization</Accordion.Header>
              <Accordion.Body>
                {workoutLog && <WorkoutVisualization showTitle={false} metric="weight" customWidth="500px" data={workoutLog.workouts} timeRange="quarterly" />}
              </Accordion.Body>
            </Accordion.Item>
          </>
        )}
      </Accordion>
    </div>
  );
}

export default WorkoutLog;
