import { createContext, useContext, useState, React, useEffect, useRef } from 'react';
import './App.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { ENV } from './assets/constants';
import DefaultNavbar from './components/defaultNavbar/DefaultNavbar';
import { HashRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
// Components
import ErrorPage from './routes/ErrorPage';
import Exercises from './routes/Exercises';
import Home from './routes/Home';
import Login from './routes/Login';
import ContactUs from './routes/ContactUs';
import Profile from './routes/Profile';
import Workouts from './routes/Workouts';
import WorkoutPlan from './routes/WorkoutPlan'; 
import WorkoutDetails from './routes/WorkoutDetails';
import WorkoutTemplates from './routes/WorkoutTemplates';
import ToastLoader from './components/toastLoader/ToastLoader';

function App() {
  const [sessionId, setSessionId] = useState(localStorage.getItem('sessionId'));
  const [showLoader, setShowLoader] = useState(false);

  const CRMLayout = ({ children }) => {
    const navigate = useNavigate();
    const logoutTimer = useRef(null); // Ref to store the timeout ID

    const userLogout = () => {
      localStorage.clear();
      setSessionId('');
      navigate('/');
    };

    useEffect(() => {
      // Set up the auto-logout timer (4 hours)
      logoutTimer.current = setTimeout(() => {
        setShowLoader(true);
        userLogout();
        setTimeout(() => setShowLoader(false), 30000); // Hide loader after 30 seconds
      }, 4 * 60 * 60 * 1000); // 4 hours in milliseconds

      // Cleanup on component unmount
      return () => {
        if (logoutTimer.current) {
          clearTimeout(logoutTimer.current);
        }
      };
    }, []); // Empty dependency array ensures this runs only once

    return (
      <>
        <DefaultNavbar fixed="bottom" userLogout={userLogout} />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/exercises" element={<Exercises />}></Route>
          <Route path="/home" element={<Home />}></Route>          
          <Route path="/user-profile" element={<Profile />}></Route>
          <Route path="/workout-details" element={<WorkoutDetails />}></Route>
          <Route path="/workout-plan" element={<WorkoutPlan />}></Route>
          <Route path="/workouts" element={<Workouts />}></Route>
          <Route path="/workout-templates" element={<WorkoutTemplates />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </>
    );
  };

  const PublicRoutes = () => (
    <>
      {showLoader && (
        <ToastLoader 
          bg="danger" 
          text="Logged Out" 
          spinner-loader={false} 
          cbt={true}
        >
          User logged out after 4 hours
        </ToastLoader>
      )}
      <Routes>
        <Route index path="/" element={<Login parentMethod={(sessionId) => setSessionId(sessionId)} />} />
        <Route path="/login" element={<Login parentMethod={(sessionId) => setSessionId(sessionId)} />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );

  return (
    <Router>
      {sessionId ? <CRMLayout /> : <PublicRoutes />}
    </Router>
  );
}

export default App;
