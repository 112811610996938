import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './starRating.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function StarRating({ rating, onRatingChange, style, locked }) {
  const [selectedStar, setSelectedStar] = useState(parseInt(rating) || 1);

  // Update selectedStar state when the rating prop changes
  useEffect(() => {
    setSelectedStar(parseInt(rating) || 1);
  }, [rating]);

  const handleRatingChange = (newRating) => {
    if (locked) return; // Prevent interaction if locked
    setSelectedStar(newRating);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  return (
    <div className="default star-rating-container">
      {[1, 2, 3, 4, 5].map((rank, i) => (
        <FontAwesomeIcon
          key={i}
          icon={faStar}
          className={rank <= selectedStar ? 'star active' : 'star'}
          style={style} // Apply the custom style
          onClick={() => handleRatingChange(rank)}
        />
      ))}
    </div>
  );
}

export default StarRating;
