import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.css';
import './workoutPanel.scss';

/* COMPONENTS */
import HealthImg from 'assets/images/icons/health_icon.svg';
import SwimmingImg from 'assets/images/swimming-pool.jpg'; // Local image for swimming background
import ManPlankImg from 'assets/images/man-plank.jpg'; // Local image for other workouts
import StarRating from 'components/starRating/StarRating';

function WorkoutPanel({ workout, styles }) {
  const navigate = useNavigate();

  const goToDetails = (workout) => {
    navigate('/workout-details', {
      state: {
        workoutId: workout._id,
      },
    });
  };

  // Determine if the workout name is 'swimming' (case-insensitive)
  const isSwimming = workout.name.toLowerCase() === 'swimming';

  // Set the background image based on the workout name
  const backgroundImage = isSwimming
    ? `url(${SwimmingImg})`
    : `url(${ManPlankImg})`;

  // Combine the passed styles with the background image style
  const combinedStyles = {
    ...styles,
    backgroundImage,
    backgroundSize: 'cover', // Ensure the background image covers the container
    backgroundPosition: 'center', // Center the background image
  };

  return (
    <>
      <div
        className="workout-panel"
        style={combinedStyles}
        onClick={() => goToDetails(workout)}
      >
        <h3>{workout.name}</h3>
        {workout.injury && (
          <Image className="health-icon" fluid src={HealthImg} />
        )}
        <StarRating rating={workout.rating} locked={true} />
      </div>
      <small>{moment(workout.date).format('M/D/YYYY h:mm:ss a')}</small>      
    </>
  );
}

export default WorkoutPanel;
